import { useState } from 'preact/hooks';

export function useQuote() {
  const [quote, updateQuote] = useState([]);

  function addToQuote(item) {
    const newQuote = [...quote, item];
    updateQuote(newQuote);
  }

  function removeFromQuote(item) {
    const newQuote = quote.filter(x => x.name !== item.name)
    updateQuote(newQuote);
  }

  return [quote, addToQuote, removeFromQuote];
}
