import { h, Component } from 'preact';
import { Router } from 'preact-router';
import Header from './header';
import { createHashHistory } from 'history';
import style from './style';

// Code-splitting is automated for routes
import Home from '../routes/home';
import Quote from '../routes/quote';

import { useQuote } from '../hooks/useQuote';

const App = () => {
  
  const [quote, addToQuote, removeFromQuote] = useQuote();

  return (
    <div id="app" class={style.app}>
      <Header quoteCount={quote.length}/>
      <Router history={createHashHistory()}>
        <Home path="/" addToQuote={addToQuote} />
        <Quote path="/quote/" quote={quote} removeFromQuote={removeFromQuote}/>
      </Router>
    </div>
  );
}
export default App
